<!--  -->
<template>
  <div class="livePanel flex-column-between">
    <!-- electronJoinClass -->
    <entranceClient
      :sitedata="true"
      @changTip="changTip"
      v-if="electronJoinClass"
    ></entranceClient>
    <entrance :sitedata="true" @changTip="changTip" v-else></entrance>
    <div class="livePanelMain flex-align-center">
      <div class="livePanelframe flex-align-center">
        <div class="createCourse">
          <div class="title">我的直播</div>
          <div class="CourseIcon"></div>
          <div class="Coursetype flex-column-center">
            <div
              class="topspeed flex-align-center"
              :class="{ join: Coursetype == 1 }"
              @click="configuration(1)"
            >
              加入课堂
            </div>
            <div
              class="topspeed flex-align-center"
              :class="{ join: Coursetype == 2 }"
              @click="configuration(2)"
            >
              极速开课
            </div>
          </div>
        </div>
        <div class="CourseList">
          <div class="screen">
            <div class="CourseSelect">
              <el-select
                v-model="value"
                placeholder="请选择"
                @change="selectStatus"
              >
                <el-option
                  v-for="item in options"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </div>

            <div class="date-picker flex-align-between">
              <span class="pickerTitle">上课时间：</span>
              <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :editable="false"
                @change="selectTime"
              >
                <!-- @input="handleDate" -->
                <!-- :picker-options="pickerOptions" -->
              </el-date-picker>
            </div>
            <div class="search flex-align-between">
              <el-input placeholder="请输入讲次名称、课堂号" v-model="input1">
              </el-input>
              <el-button
                type="success"
                icon="el-icon-search"
                @click="searchClass"
                >搜索
              </el-button>
              <span class="refresh" @click="refreshClass">刷新</span>
            </div>
          </div>
          <div
            class="CourseData"
            :class="[
              classList.length != 0 ? 'column-betweent' : 'align-center',
            ]"
            ref="CourseData"
          >
            <div class="CourseDataListframe" v-if="classList.length != 0">
              <div
                class="CourseDataList flex-align-between"
                v-for="(item, index) in classList"
                :key="index"
              >
                <div class="courseInformation">
                  <div class="courseName">
                    <span>{{ item.className }}</span>
                  </div>
                  <div class="startCourseTime flex-align">
                    <span>开课时间：</span>
                    <div class="flex-align">
                      <span class="start"
                        >{{ dayjs(item.startTime * 1000).format("YYYY-MM-DD") }}
                      </span>
                      <span class="end">
                        {{ dayjs(item.startTime * 1000).format("HH:mm:ss") }}
                        &nbsp;&nbsp;- &nbsp;&nbsp;
                        {{
                          dayjs(item.endTime * 1000).format("HH:mm:ss")
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="ResearchCourse unificationStyle flex-align">
                    <span>课程类型：</span>
                    <span>{{ item.type == 1 ? "互动班课" : "公开课" }}</span>
                  </div>
                  <div class="lecturer unificationStyle flex-align">
                    <span>课程主讲：</span>
                    <span>{{ item.teacherName }}</span>
                  </div>
                  <div class="courseNumber flex-align">
                    <div>课堂号：</div>
                    <div :id="'copyCode' + index">{{ item.classCode }}</div>
                    <div
                      @click="copyCode(index)"
                      :class="'copyTrackingNumber' + index"
                      :data-clipboard-target="'#copyCode' + index"
                    >
                      复制
                    </div>
                  </div>
                </div>

                <div class="operation flex-align-between">
                  <div
                    class="courseStatus"
                    :style="{ color: courseStatus[item.status].color }"
                  >
                    <i
                      class="el-icon-s-data"
                      :style="{ color: courseStatus[item.status].iconColor }"
                    ></i>
                    <span>{{ courseStatus[item.status].name }}</span>
                  </div>
                  <div
                    class="courseStatusBtn btnStyle"
                    :class="[
                      btnStatus[item.status].className,
                      item.status == 1 ? 'btnStatus1' : '',
                      greenBtnIndex == 1
                        ? greenIndex == index
                          ? 'btnStatus1'
                          : ''
                        : '',
                    ]"
                    v-if="item.status != 2"
                    @click="
                      entranceBtn(
                        item.status,
                        item.id,
                        item.playBackUrl,
                        item.className,
                        item.classId
                      ),
                        greenBtn(
                          index,
                          1,
                          item.status,
                          item.playBackUrl,
                          item.className
                        )
                    "
                  >
                    {{ operation[item.status].name }}
                  </div>

                  <div
                    class="courseStatusBtn btnStyle"
                    :class="[
                      item.playBackUrl == null ? 'btnStatus3' : 'btnStatus2',
                      greenBtnIndex == 1
                        ? greenIndex == index
                          ? 'btnStatus1'
                          : ''
                        : '',
                    ]"
                    @click="
                      entranceBtn(
                        item.status,
                        item.id,
                        item.playBackUrl,
                        item.className,
                        item.classId
                      ),
                        greenBtn(
                          index,
                          1,
                          item.status,
                          item.playBackUrl,
                          item.className
                        )
                    "
                    v-else
                  >
                    <el-tooltip
                      id="item"
                      :disabled="item.playBackUrl == null ? false : true"
                      content="回放生成中，请稍后查看"
                      placement="bottom"
                      effect="light"
                      :manual="false"
                    >
                      <span>查看回放</span>
                    </el-tooltip>
                  </div>
                  <!-- 分享 -->
                  <div
                    class="courseShare btnStyle"
                    :class="[
                      item.status == 0 || item.status == 1
                        ? 'btnStatus2'
                        : item.status == 2
                        ? item.playBackUrl != null
                          ? 'btnStatus2'
                          : 'btnStatus3'
                        : 'btnStatus3',
                      greenBtnIndex == 2
                        ? greenIndex == index
                          ? 'btnStatus1'
                          : ''
                        : '',
                    ]"
                    @click="
                      sign(
                        item.status,
                        item.id,
                        item.playBackUrl,
                        item.className,
                        item.classId
                      ),
                        ShareBtn(
                          index,
                          2,
                          item.status,
                          item.playBackUrl,
                          item.className
                        )
                    "
                  >
                    分享
                  </div>
                  <!-- 更多 -->
                  <div class="more" ref="showPanel">
                    <div
                      v-if="
                        item.status == 1 ||
                        item.status == 2 ||
                        item.teacherId != teacherId
                      "
                      class="btnStatus3 btnStyle"
                    >
                      更多
                    </div>
                    <div class="moreBox" v-else>
                      <div
                        class="btnStyle"
                        :class="[
                          item.teacherId == teacherId
                            ? greenBtnIndex == 3
                              ? greenIndex == index
                                ? 'btnStatus1'
                                : ''
                              : ''
                            : '',

                          item.teacherId == teacherId
                            ? moreStatus[item.status].className
                            : 'btnStatus3',
                        ]"
                        @click="
                          greenBtn(
                            index,
                            3,
                            item.status,
                            item.playBackUrl,
                            item.className
                          )
                        "
                      >
                        更多
                      </div>
                      <div
                        class="popup"
                        v-show="moreIndex == index && greenBtnIndex == 3"
                      >
                        <span><em></em></span>
                        <div>
                          <el-button
                            :disabled="
                              item.teacherId == teacherId
                                ? moreStatus[item.status].status1
                                : true
                            "
                            @click.native="
                              item.type == 1
                                ? rtcClass(item.id)
                                : liveClass(item.id)
                            "
                            >编辑</el-button
                          >
                        </div>
                        <div>
                          <el-button
                            :disabled="
                              item.teacherId == teacherId
                                ? moreStatus[item.status].status2
                                : true
                            "
                            @click.native="getdelId(item.id, item.className)"
                            >删除</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="placeholder flex-column-center" v-else>
              <img src="../../assets/live/placeholder.png" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 加入课堂 -->
    <div class="joinPop">
      <el-dialog
        title="加入课堂"
        :visible="dialogVisible"
        center
        @close="closedialog1"
      >
        <div class="mian flex-align-center">
          <el-input
            placeholder="请输入课堂号"
            v-model="joinClassId"
            autofocus="true"
          ></el-input>
          <div class="btn flex-align-center" @click="joinClassByCode">
            加入课堂
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 分享回放 -->
    <div class="quicknessPop">
      <el-dialog
        title="分享回放"
        :visible="dialogVisible4"
        width="30%"
        center
        @close="closedialog2"
      >
        <div class="mian">
          <div class="quickness" :class="'quickness' + signIndex">
            <ul>
              <li>
                <p>
                  <span class="title">回放：</span>
                  <span class="text">余秋雨的课堂0445-5941-06</span>
                </p>
              </li>
              <li>
                <p>
                  <span class="title">日期：</span>
                  <span class="text">2022-03-30 17:36</span>
                </p>
              </li>
              <li>
                <span class="title">回放链接：</span>
                <span class="text"
                  >https://lanhuapp.com/web/#/item/projec
                  t/stage?tid=691a80ad-b649-4866-92bf-0
                  01ffc720942&pid=7f0d31e9-8056-4a4c-9 464-e778b1902ff6</span
                >
              </li>
              <li>
                <p>
                  <span class="title">有效期：</span>
                  <span class="text">于2022-04-30 17:36过期</span>
                </p>
              </li>
            </ul>
          </div>
          <div class="quicknessBtn flex-align-around">
            <div
              class="flex-align-center"
              :class="'copyTrackingtip' + signIndex"
              @click="quicknessClassCopy"
              :data-clipboard-target="'.quickness' + signIndex"
            >
              复制
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 极速开课成功 -->
    <div class="sharePop">
      <el-dialog
        :title="shareTitle"
        :visible="dialogVisible3"
        width="30%"
        center
        @close="closedialog3"
      >
        <div class="mian">
          <div class="share">
            <ul>
              <li>
                <p>
                  <span class="title">课堂名：</span>
                  <span class="text">{{ winClassName }}</span>
                </p>
              </li>
              <li>
                <p>
                  <span class="title">课堂号：</span>
                  <span class="text" id="sharePopcopyCode">{{
                    winClassCode
                  }}</span>
                  <span
                    @click="sharePopcopyCode()"
                    class="sharePopNumber"
                    data-clipboard-target="#sharePopcopyCode"
                    v-if="fastStatus"
                    >复制</span
                  >
                </p>
              </li>
              <li>
                <p>
                  <span class="title">时间：</span>
                  <span class="text">
                    {{
                      dayjs(winClassstartTime * 1000).format("YYYY-MM-DD")
                    }}&nbsp;&nbsp;
                    {{
                      dayjs(winClassstartTime * 1000).format("HH:mm:ss")
                    }}&nbsp;&nbsp; -&nbsp;&nbsp;{{
                      dayjs(winClassendTime * 1000).format("HH:mm:ss")
                    }}
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span class="title">教师：</span>
                  <span class="text">{{ winClassTeachaer }}</span>
                </p>
              </li>
              <li>
                <p>
                  <span class="title special">上课方式：</span>
                  <span class="text">
                    ①电脑端听课：点击链接http://yun.cnuzi.com/进入首师优字，选择【我的直播-加入课堂-输入课堂号】上课。
                  </span>
                  <span class="text">
                    ②手机端听课：在微信中搜索【首师优字】小程序，登录后选择【直播-加入课堂-输入课堂号】上课。
                  </span>
                </p>
              </li>
            </ul>
            <!-- <div class="shareClassName flex-align shareStyle">
              <div>课堂名</div>
              <div>{{ winClassName }}</div>
            </div>
            <div class="shareClassMark flex-align shareStyle">
              <div>课堂号</div>
              <div id="sharePopcopyCode">{{ winClassCode }}</div>
              <div
                @click="sharePopcopyCode()"
                class="sharePopNumber"
                data-clipboard-target="#sharePopcopyCode"
              >
                复制
              </div>
            </div>
            <div class="shareClassTime flex-align shareStyle">
              <div>时间</div>
              <div>
                {{
                  dayjs(winClassstartTime * 1000).format("YYYY-MM-DD")
                }}&nbsp;&nbsp;
              </div>
              <div>
                {{
                  dayjs(winClassstartTime * 1000).format("HH:mm:ss")
                }}&nbsp;&nbsp; -&nbsp;&nbsp;{{
                  dayjs(winClassendTime * 1000).format("HH:mm:ss")
                }}
              </div>
            </div>
            <div class="shareteacher flex-align shareStyle">
              <div>教师</div>
              <div>{{ winClassTeachaer }}</div>
            </div>
            <div class="sharecCnnect flex-align" v-if="false">
              <div class="sharecCnnectN">链接</div>
              <div class="sharecurl flex-column">
                <div>
                  {{ winClassUrl }}
                </div>
                <div>点击打开链接参加直播课</div>
              </div>
            </div> -->
          </div>
          <div class="shareBtn flex-align-around">
            <div
              class="flex-align-center shareBtn1"
              data-clipboard-target=".share"
              @click="shareClassCopy"
            >
              复制
            </div>
            <div class="flex-align-center" v-if="false" @click="entranceClass">
              进入课堂
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 进入直播 -->
    <div class="entrancePop">
      <el-dialog
        title="进入直播教室"
        :visible="dialogVisible2"
        :close-on-click-modal="false"
        width="30%"
        center
        @close="closedialog4"
      >
        <div class="mian flex-column-center">
          <div class="entranceText">
            客户端直播上课更流畅，功能更强大，建议使用客户端进入教室。
            如未安装客户端，请点<span style="color: #21bd99"
              ><a style="color: #21bd99" :href="DownloadAddress"
                >击下载客户端</a
              ></span
            >
          </div>
          <div class="entranceBtn flex-align-between">
            <div class="flex-align-center" @click="webpage">网页进入教室</div>
            <div class="flex-align-center" @click="client">客户端进入教室</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- dialogVisible5 -->
    <!-- 极速开课1 -->
    <div class="quickPop">
      <el-dialog
        title="极速开课"
        :visible="dialogVisible5"
        center
        @close="closedialog5"
      >
        <div class="mian flex-column-center">
          <div class="quick">
            <div class="quickClassName flex-align quickStyle">
              <div class="name">课堂名称</div>
              <div class="quicktitle">
                <el-input
                  type="text"
                  v-model="quickinput"
                  placeholder="课堂名称"
                  :maxlength="30"
                  show-word-limit
                >
                </el-input>
              </div>
            </div>
            <div class="quickClassType flex-align quickStyle">
              <div class="name">课堂类型</div>
              <div class="flex-align">
                <el-radio
                  v-model="radio"
                  label="1"
                  :disabled="editStatus && radio != 1"
                  @change="radiochange()"
                  >互动班课
                </el-radio>
                <el-radio
                  v-model="radio"
                  label="2"
                  :disabled="editStatus && radio != 2"
                  @change="radiochange()"
                  >公开课
                </el-radio>
              </div>
            </div>
            <!-- radio == 2 -->
            <div class="quickClassintroduce flex-align quickStyle" v-if="false">
              <div class="name">课堂简介</div>
              <div class="textarea flex-align">
                <el-input
                  type="textarea"
                  placeholder="请输入课堂简介"
                  v-model="textarea"
                  :maxlength="100"
                  show-word-limit
                >
                  <!-- autosize -->
                </el-input>
              </div>
            </div>
            <!-- v-if="radio == 2" -->
            <div class="quickClasscover flex-align quickStyle" v-if="false">
              <div class="name">课堂封面</div>
              <div class="cover flex-align">
                <div class="applet flex-column">
                  <div class="title">小程序详情封面</div>
                  <div class="modification flex-align-between">
                    <div class="exist flex-column">
                      <div class="bigCover">
                        <img src="../../assets/live/big.png" />
                      </div>
                      <div class="alter">
                        <el-upload
                          class="avatar-uploader"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                          :limit="2"
                        >
                          <span>更改</span>
                        </el-upload>
                      </div>
                    </div>
                    <!-- <div class="without flex-column">
                                                                  <div class="bigCover2 flex-align-center">
                                                                        <el-upload class="avatar-uploader"
                                                                              action="https://jsonplaceholder.typicode.com/posts/"
                                                                              :show-file-list="false"
                                                                              :on-success="handleAvatarSuccess"
                                                                              :before-upload="beforeAvatarUpload">
                                                                              <img v-if="imageUrl" :src="imageUrl"
                                                                                    class="avatar" />
                                                                              <i v-else
                                                                                    class="el-icon-plus avatar-uploader-icon"></i>
                                                                        </el-upload>
                                                                  </div>
                                                                  <div class="alter">更改</div>
                                                            </div> -->
                  </div>
                  <div class="hint">
                    提示：建议尺寸750*420，比例16:9，大小不超过1M的jpg图片。
                  </div>
                  <div class="share flex-column">
                    <div class="title">分享封面</div>
                    <div class="small flex-column">
                      <div class="smallCover">
                        <img src="../../assets/live/big.png" />
                      </div>
                      <div class="alter">
                        <el-upload
                          class="avatar-uploader"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                        >
                          <span>更改</span>
                        </el-upload>
                      </div>
                    </div>
                  </div>
                  <div class="hint">
                    提示：建议尺寸520*416，比例5:4，大小不超过1M的jpg图片。
                  </div>
                </div>
              </div>
            </div>
            <div class="quickTime flex-align quickStyle">
              <div class="name">上课时间</div>
              <div class="flex-column">
                <div class="date">
                  <el-date-picker
                    v-model="quickvalue"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    :clearable="false"
                    :editable="false"
                    :picker-options="expireTimeOption"
                    @change="pickertTime"
                  >
                  </el-date-picker>
                </div>
                <div class="time flex-align-between">
                  <el-time-select
                    placeholder="起始时间"
                    v-model="startTime"
                    :clearable="false"
                    :editable="false"
                    :picker-options="{
                      start: '00:05',
                      step: '00:05',
                      end: '23:55',
                      minTime: minTime1,
                    }"
                    @change="startChange"
                  >
                    <!-- end: Deadline1,
                      minTime: minTime1, -->
                    <!-- Deadline -->
                    <!-- end: '23:59', -->
                  </el-time-select>
                  <el-time-select
                    placeholder="结束时间"
                    v-model="endTime"
                    :editable="false"
                    :clearable="false"
                    :picker-options="{
                      start: '00:05',
                      step: '00:05',
                      end: '23:55',
                      minTime: minTime2,
                    }"
                  >
                  </el-time-select>

                  <!-- end: Deadline2,
                      minTime: minTime2, -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="btn flex-align-center"
            @click="radio == 1 ? createClass() : createliveClass()"
          >
            开课
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="DelPop">
      <el-dialog
        title="确定删除该课堂吗？"
        :visible.sync="DelDialogVisible"
        center
      >
        <div class="DelPopText flex-align-center">
          {{ courseName }}数据将清空
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="DelDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="deleteClass">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
  </div>
</template>

<script>
import entranceClient from "../../Common_components/head/entranceClient";
import entrance from "../../Common_components/head/entrance";
import { mapState, mapMutations } from "vuex";
import ClipboardJS from "clipboard";
export default {
  components: {
    entranceClient,
    entrance,
  },
  data() {
    return {
      centerDialogVisible2: false,
      Coursetype: null,
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      //类别
      options: [
        {
          dictCode: "",
          dictLabel: "全部",
          dictValue: "",
        },
      ],
      courseStatus: {
        0: {
          name: "未开课",
          color: "#FFA902",
          iconColor: "transparent",
        },
        // 已开始
        1: {
          name: "上课中",
          color: "#5995ED",
          iconColor: "#5995ED",
        },

        2: {
          name: "已结束",
          color: "#C8C8C8",
          iconColor: "transparent",
        },
        3: {
          name: "已过期",
          color: "#C8C8C8",
          iconColor: "transparent",
        },
      },
      operation: {
        // 未开始
        0: {
          name: "进入课堂",
        },
        // 已开始
        1: {
          name: "进入课堂",
        },
        //已结束
        2: {
          name: "已结束",
        },
        //已过期
        3: {
          name: "进入课堂",
        },
      },
      //第三个按钮
      moreStatus: {
        0: {
          className: "btnStatus2",
          status1: false,
          status2: false,
        },
        1: {
          className: "btnStatus3",
          status1: true,
          status2: true,
        },
        2: {
          className: "btnStatus3",
          status1: true,
          status2: true,
        },
        3: {
          className: "btnStatus2",
          status1: true,
          status2: false,
        },
      },
      //第一个按钮
      btnStatus: {
        //未开始
        0: {
          className: "btnStatus2",
        },
        //已开始
        1: {
          className: "btnStatus2",
        },
        //已结束
        2: {
          className: "btnStatus3",
        },
        //已过期
        3: {
          className: "btnStatus3",
        },
      },
      greenStatus: false,
      greenIndex: null,
      greenBtnIndex: null,
      classList: [],
      value: "",
      value1: [],
      input1: "",
      joinClassId: "",
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      quickinput: "",
      radio: "1",
      quickvalue: "",
      startTime: "",
      endTime: "",
      textarea: "",
      imageUrl: "",
      signIndex: null,
      //开课成功
      winClassId: "",
      winClassName: "",
      winClassCode: "",
      winClassstartTime: "",
      winClassendTime: "",
      winClassTeachaer: "",
      winClassUrl: "",
      fastStatus: true,

      // 直播地址类型
      pcLiveUrl: "",
      webLiveUrl: "",
      minTime1: "",
      minTime2: "",
      teacherId: "",
      NumberClasses: "",
      moreIndex: null,
      DelDialogVisible: false,
      courseName: "",
      delId: null,
      DownloadAddress: "",
      electronJoinClass: "",
      throttleStatus: true,
      Deadline1: "",
      Deadline2: "",
      shareTitle: "",
      editStatus: false,
      editClassId: null,
    };
  },
  //方法
  methods: {
    ...mapMutations(["hint_data"]),
    changTip(value) {
      if (value) {
        this.hint_data("暂无活动");
        this.centerDialogVisible2 = true;
      }
    },
    clear(bool) {
      this.centerDialogVisible2 = bool;
    },
    //获取课堂分页列表
    async getRoomPage(status, startTime, endTime, searchField, relClassId) {
      let data = {
        startTime: startTime,
        endTime: endTime,
        searchField: searchField,
        status: status,
        relClassId: relClassId,
        page: 1,
        limit: 999,
      };
      let resData = await this.$Api.Live.getRoomPage(data);
      this.classList = resData.data.list;
    },
    //查询课程状态
    async dictionary() {
      let resData = await this.$Api.Live.dictionary("tencent_class_status");
      this.options = this.options.concat(resData.data);
    },
    //删除课程
    getdelId(id, name) {
      this.delId = id;
      this.courseName = name;
      this.DelDialogVisible = true;
    },
    async deleteClass() {
      let _this = this;
      this.moreIndex = null;
      let resData = await this.$Api.Live.deleteClass(this.delId);
      if (resData.code == 200) {
        setTimeout(() => {
          _this.$message({
            message: "删除成功",
            type: "success",
            center: true,
            duration: 2000,
          });
        }, 400);

        this.greenBtnIndex = null;
        this.moreIndex = null;
        this.delId = null;
        this.courseName = null;
        this.DelDialogVisible = false;
        this.searchClass();
      } else if (resData.code == 500) {
        setTimeout(() => {
          _this.$message({
            message: resData.msg,
            type: "error",
            center: true,
            duration: 2000,
          });
        }, 400);

        this.greenBtnIndex = null;
        this.moreIndex = null;
        this.delId = null;
        this.courseName = null;
        this.DelDialogVisible = false;
        // this.getRoomPage("", "", "", "", "");
      }
    },
    resumeStateFun(id) {
      console.log("0000000", id);
    },
    //开课类型
    configuration(value) {
      this.Coursetype = value;
      if (value == 1) {
        this.dialogVisible = true;
      } else {
        this.queryDailyCount();
        this.dialogVisible5 = true;
        // //当前时间
        let currentTime = this.dayjs(new Date()).format("HH:mm");
        console.log("currentTime-----", currentTime.slice(-2));
        if (
          currentTime.slice(0, 2) == "23" &&
          Number(currentTime.slice(-2)) >= 55
        ) {
          this.quickvalue = this.dayjs(new Date())
            .add(1, "day")
            .format("YYYY-MM-DD");
          this.startTime = "08:00";
          this.minTime1 = "00:00";
          this.endTime = "09:00";
          this.minTime2 = "00:00";
        } else {
          this.quickvalue = this.dayjs(new Date()).format("YYYY-MM-DD");
          // // 矫正时间
          let startTimeSt =
            5 - (currentTime.substr(currentTime.length - 1, 1) % 5);
          this.startTime = this.dayjs(new Date())
            .add(startTimeSt + 10, "minute")
            .format("HH:mm");
          if (currentTime.slice(0, 2) == "23") {
            this.endTime = "23:55";
          } else {
            this.endTime = this.dayjs(new Date())
              .add(startTimeSt + 10, "minute")
              .add(1, "hour")
              .format("HH:mm");
          }
          this.minTime1 = this.dayjs(new Date()).format("HH:mm");
          this.minTime2 = this.dayjs(new Date()).format("HH:mm");
        }
      }
    },
    //根据班级ID加入课堂
    async joinClassByCode() {
      let _this = this;
      if (this.joinClassId == "") {
        alert("请输入课程号");
        return;
      }
      this.dialogVisible = false;
      let resData = await this.$Api.Live.joinClassByCode(this.joinClassId);
      if (resData.code == 200) {
        this.recoveryScrollTop();
        this.pcLiveUrl = resData.data.pcLiveUrl;
        this.webLiveUrl = resData.data.webLiveUrl;
        // this.electronJoinClass
        if (this.electronJoinClass) {
          this.getMpClassByCode(resData.data.classCode);
        } else {
          this.dialogVisible2 = true;
        }
        this.getRoomPage("", "", "", "", "");
        this.joinClassId = "";
      } else {
        setTimeout(() => {
          _this.$message({
            message: resData.msg,
            type: "error",
            center: true,
            duration: 2000,
          });
          this.joinClassId = "";
        }, 400);
      }
    },
    //根据班级ID加入课堂
    async joinClass(id, classId) {
      let resData = await this.$Api.Live.joinClass(id);
      if (resData.code == 200) {
        this.pcLiveUrl = resData.data.pcLiveUrl;
        this.webLiveUrl = resData.data.webLiveUrl;
        if (this.electronJoinClass) {
          this.getMpClassByCode(classId);
        } else {
          this.dialogVisible2 = true;
        }
      }
    },
    //客户端进入直播间
    async getMpClassByCode(id) {
      let data = {
        classCode: id,
      };
      let resData = await this.$Api.Live.getMpClassByCode(data);
      if (resData.code == 200) {
        window.electronJoinClass(
          resData.data.schoolId,
          resData.data.classId,
          resData.data.userId,
          resData.data.token
        );
      }
    },
    //网页进入教室
    //进入直播
    webpage() {
      this.dialogVisible2 = false;
      let that = this;

      setTimeout(() => {
        if (window.electronJoinClass == undefined) {
          window.open(that.webLiveUrl);
        } else {
          window.location.href = that.webLiveUrl;
        }
        this.joinClassId = "";
        this.webLiveUrl = "";
        this.greenIndex = null;
        this.greenBtnIndex = null;
        this.refreshClass();
      }, 500);
    },

    //客户端进入教室
    client() {
      this.judge();
      // if (this.throttleStatus) {
      //       this.judge();
      //       this.throttleStatus = false;
      // }
      // let _this = this;
      // setTimeout(() => {
      //       console.log('111')
      //       _this.throttleStatus = true;
      // }, 3000);
    },
    judge() {
      let _this = this;
      let protocalUrl = this.pcLiveUrl;
      this.openUriWithInputTimeoutHack(
        protocalUrl,
        () => {
          console.log("检测到，未安装客户端");
          _this.$message({
            message: "检测到：您未安装客户端，请前往下载",
            type: "error",
            center: true,
            duration: 1000,
          });
          _this.throttleStatus = false;
        },
        () => {
          // 浏览器弹窗提示
          console.log("检测到：已安装了客户端");
          this.dialogVisible2 = false;
          this.joinClassId = "";
          this.pcLiveUrl = "";
          this.greenIndex = null;
          this.greenBtnIndex = null;
          _this.throttleStatus = true;
        }
      );
    },

    greenBtn(index, btnindex, status, url) {
      if (status == 0 && btnindex == 1) {
        //第0种状态
        this.greenIndex = index;
        this.moreIndex = index;
        this.greenBtnIndex = btnindex;
      } else if (status == 0 && btnindex == 3) {
        //第0种状态
        this.greenIndex = index;
        this.moreIndex = index;
        this.greenBtnIndex = btnindex;
      } else if (status == 1 && btnindex == 1) {
        //第1种状态
        this.greenIndex = index;
        this.moreIndex = index;
        this.greenBtnIndex = btnindex;
      } else if (status == 1 && btnindex == 3) {
        //第1种状态
        this.greenIndex = null;
        this.greenBtnIndex = null;
      } else if (status == 2 && btnindex == 1 && url != null) {
        //第2种状态
        this.greenIndex = index;
        this.moreIndex = index;
        this.greenBtnIndex = btnindex;
      } else if (status == 3 && btnindex == 3) {
        //第2种状态
        this.greenIndex = index;
        this.moreIndex = index;
        this.greenBtnIndex = btnindex;
      } else {
        this.greenIndex = null;
        this.greenBtnIndex = null;
      }
    },
    //operation
    entranceBtn(type, id, Url, name, classId) {
      if (type == 0) {
        // this.dialogVisible2 = true;
        this.joinClass(id, classId);
        this.greenStatus = true;
      } else if (type == 1) {
        // this.dialogVisible2 = true;
        this.joinClass(id, classId);
        this.greenStatus = true;
      } else if (type == 2) {
        if (Url != null) {
          this.greenStatus = true;
          console.log("Url", Url);
          this.$router.push({
            name: "liveVideo",
            query: {
              Url: Url,
              name: name,
            },
          });
        } else {
          this.greenStatus = false;
        }

        //判断是否有回放
        // this.getPlaybackUrl(id);
      } else if (type == 3) {
        this.greenStatus = false;
      }
    },

    //用户创建RTC课程
    async createClass() {
      console.log("++++++++++++++++++++互动课");
      let _this = this;
      if (this.quickinput == "") {
        _this.$message({
          message: "请输入课堂名称",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else if (this.quickvalue == "") {
        _this.$message({
          message: "请输入开课日期",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else if (this.startTime == "") {
        _this.$message({
          message: "请输入开课日期",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else if (this.endTime == "") {
        _this.$message({
          message: "请输入开课日期",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else {
        let screenDate;
        if (JSON.stringify(this.quickvalue).slice(-2, -1) == "Z") {
          screenDate = this.dayjs(this.quickvalue).format("YYYY-MM-DD");
        } else {
          screenDate = this.quickvalue;
        }
        let startTime =
          new Date(screenDate + " " + this.startTime).getTime() / 1000;
        let endTime =
          new Date(screenDate + " " + this.endTime).getTime() / 1000;

        if (this.editStatus) {
          let data = {
            // classId: this.editClassId,
            className: this.quickinput,
            startTime: startTime,
            endTime: endTime,
          };
          let resData = await this.$Api.Live.rtcUpdate(this.editClassId, data);
          console.log("修改RTC课程", resData.data.code);
          if (resData.data.code) {
            this.recoveryScrollTop();
            this.shareTitle = "极速开课成功";
            this.getparticulars(this.editClassId);
            this.editStatus = false;
            this.editClassId = null;
            this.winClassId = this.editClassId;
            this.dialogVisible5 = false;
            this.dialogVisible3 = true;
            this.quickinput = "";
            this.quickvalue = "";
            this.startTime = "";
            this.endTime = "";
            // this.getRoomPage("", "", "", "", "");
            this.searchClass();
          } else {
            _this.$message({
              message: resData.msg,
              type: "error",
              center: true,
              duration: 1500,
            });
          }
        } else {
          let data = {
            className: this.quickinput,
            startTime: startTime,
            endTime: endTime,
          };
          let resData = await this.$Api.Live.createClass(data);
          if (resData.code == 200) {
            this.recoveryScrollTop();
            this.winClassId = resData.data;
            this.shareTitle = "极速开课成功";
            this.getparticulars(this.winClassId);
            this.dialogVisible5 = false;
            this.dialogVisible3 = true;
            this.quickinput = "";
            this.quickvalue = "";
            this.startTime = "";
            this.endTime = "";
            this.value = "";
            this.value1 = [];
            this.input1 = "";

            this.getRoomPage("", "", "", "", "");
          } else {
            _this.$message({
              message: resData.msg,
              type: "error",
              center: true,
              duration: 1500,
            });
          }
        }
      }
    },
    //用户创建live课程
    async createliveClass() {
      //课堂简介 this.textarea
      console.log("++++++++++++++++++++公开课");
      let _this = this;
      if (this.quickinput == "") {
        _this.$message({
          message: "请输入课堂名称",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else if (this.quickvalue == "") {
        _this.$message({
          message: "请输入开课日期",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else if (this.startTime == "") {
        _this.$message({
          message: "请输入开课日期",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else if (this.endTime == "") {
        _this.$message({
          message: "请输入开课日期",
          type: "error",
          center: true,
          duration: 1000,
        });
      } else {
        let screenDate;
        if (JSON.stringify(this.quickvalue).slice(-2, -1) == "Z") {
          screenDate = this.dayjs(this.quickvalue).format("YYYY-MM-DD");
        } else {
          screenDate = this.quickvalue;
        }
        let startTime =
          new Date(screenDate + " " + this.startTime).getTime() / 1000;
        let endTime =
          new Date(screenDate + " " + this.endTime).getTime() / 1000;
        let data = {
          className: this.quickinput,
          startTime: startTime,
          endTime: endTime,
          introduction: this.textarea,
          cover: "",
          shareCover: "",
        };
        if (this.editStatus) {
          let resData = await this.$Api.Live.liveUpdate(this.editClassId, data);
          if (resData.data.code == 200) {
            this.recoveryScrollTop();
            this.shareTitle = "极速开课成功";
            this.getparticulars(this.editClassId);
            this.editStatus = false;
            this.editClassId = null;
            this.winClassId = this.editClassId;
            this.dialogVisible5 = false;
            this.dialogVisible3 = true;
            this.radio = "1";
            this.textarea = "";
            this.quickinput = "";
            this.quickvalue = "";
            this.startTime = "";
            this.endTime = "";
            // this.getRoomPage("", "", "", "", "");
            this.searchClass();
          } else {
            _this.$message({
              // message: "课程开始时间不可早于当前时间，请重新选择",
              message: resData.msg,
              type: "error",
              center: true,
              duration: 1500,
            });
          }
        } else {
          let resData = await this.$Api.Live.createliveClass(data);
          console.log("resData", resData);
          if (resData.code == 200) {
            this.recoveryScrollTop();
            this.winClassId = resData.data;
            this.getparticulars(this.winClassId);
            this.dialogVisible5 = false;
            this.dialogVisible3 = true;
            this.radio = "1";
            this.textarea = "";
            this.quickinput = "";
            this.quickvalue = "";
            this.startTime = "";
            this.endTime = "";
            this.value = "";
            this.value1 = [];
            this.input1 = "";
            this.getRoomPage("", "", "", "", "");
          } else {
            _this.$message({
              // message: "课程开始时间不可早于当前时间，请重新选择",
              message: resData.msg,
              type: "error",
              center: true,
              duration: 1500,
            });
          }
        }
      }
    },
    //查看RTC课程时回显
    async rtcClass(id) {
      let resData = await this.$Api.Live.rtcClass(id);
      if (resData.code != 200) return false;
      this.dialogVisible5 = true;
      this.editStatus = true;
      this.editClassId = resData.data.id;
      //课堂名称
      this.quickinput = resData.data.className;
      // 类型
      this.radio = "1";
      //当前日期
      this.quickvalue = this.dayjs(
        new Date(resData.data.startTime * 1000)
      ).format("YYYY-MM-DD");
      //默认开始时间
      this.startTime = this.dayjs(
        new Date(resData.data.startTime * 1000)
      ).format("HH:mm");
      //默认结束时间
      this.endTime = this.dayjs(new Date(resData.data.endTime * 1000)).format(
        "HH:mm"
      );

      this.minTime1 = this.dayjs(new Date(resData.data.startTime * 1000))
        .subtract(5, "minute")
        .format("HH:mm");
      this.minTime2 = this.dayjs(new Date(resData.data.startTime * 1000))
        .subtract(5, "minute")
        .format("HH:mm");
      this.moreIndex = null;
    },
    //用户创建live课程
    async liveClass(id) {
      let resData = await this.$Api.Live.liveClass(id);
      if (resData.code != 200) return false;
      this.dialogVisible5 = true;
      this.editStatus = true;
      this.editClassId = resData.data.id;
      //课堂名称
      this.quickinput = resData.data.className;
      // 类型
      this.radio = "2";
      //当前日期
      this.quickvalue = this.dayjs(
        new Date(resData.data.startTime * 1000)
      ).format("YYYY-MM-DD");
      //默认开始时间
      this.startTime = this.dayjs(
        new Date(resData.data.startTime * 1000)
      ).format("HH:mm");
      //默认结束时间
      this.endTime = this.dayjs(new Date(resData.data.endTime * 1000)).format(
        "HH:mm"
      );

      this.minTime1 = this.dayjs(new Date(resData.data.startTime * 1000))
        .subtract(5, "minute")
        .format("HH:mm");
      this.minTime2 = this.dayjs(new Date(resData.data.startTime * 1000))
        .subtract(5, "minute")
        .format("HH:mm");
      this.moreIndex = null;
    },
    //根据课堂ID得到详细数据
    async getparticulars(id) {
      let resData = await this.$Api.Live.getparticulars(id);
      console.log("getparticulars", resData.data);
      if (resData.code == 200) {
        this.winClassName = resData.data.className;
        this.winClassCode = resData.data.classCode;
        this.winClassstartTime = resData.data.startTime;
        this.winClassendTime = resData.data.endTime;
        this.winClassTeachaer = resData.data.teacherName;
        this.winClassUrl = resData.data.liveUrl;
        // resData.data
      }
    },
    //极速开课成功
    shareClassCopy() {
      this.fastStatus = false;
      this.dialogVisible3 = false;
      let _this = this;
      let clipboard = new ClipboardJS(".shareBtn1");
      clipboard.on("success", function (e) {
        _this.$message({
          message: "已复制到粘贴板",
          type: "success",
          center: true,
          duration: 1000,
        });
        e.clearSelection();
        _this.fastStatus = true;
      });
      clipboard.on("error", function (e) {
        _this.$message.error("复制失败");
        console.log(e);
        _this.fastStatus = true;
      });
      // 复制
    },
    entranceClass() {
      this.dialogVisible3 = false;
      //进入课堂
      window.open(this.winClassUrl);
    },

    //获取查看回放地址
    async getPlaybackUrl(id) {
      let resData = await this.$Api.Live.getPlaybackUrl(id);
      console.log("getPlaybackUrl", resData.data);
      if (resData.code == 200) {
      }
    },

    //极速开课成功
    sign(type, id, Url, name, classId) {
      if (type == 0) {
        this.shareTitle = "分享";
        this.dialogVisible3 = true;
        this.getparticulars(id);
      } else if (type == 1) {
        this.shareTitle = "分享";
        this.dialogVisible3 = true;
        this.getparticulars(id);
      } else if (type == 2) {
        if (Url != null) {
          this.shareTitle = "分享";
          this.dialogVisible3 = true;
          this.getparticulars(id);
        }
      }
    },
    ShareBtn(index, btnindex, status, url) {
      if (status == 0 && btnindex == 2) {
        //第0种状态
        this.greenIndex = index;
        this.greenBtnIndex = btnindex;
      } else if (status == 1 && btnindex == 2) {
        this.greenIndex = index;
        this.greenBtnIndex = btnindex;
      } else if (status == 2 && btnindex == 2 && url != null) {
        this.greenIndex = index;
        this.greenBtnIndex = btnindex;
      }
    },
    quicknessClassCopy() {
      this.dialogVisible4 = false;
      let _this = this;
      let clipboard = new ClipboardJS(".copyTrackingtip" + this.signIndex);
      clipboard.on("success", function (e) {
        _this.$message({
          message: "已复制到粘贴板",
          type: "success",
          center: true,
          duration: 1000,
        });
        e.clearSelection();
      });
      clipboard.on("error", function (e) {
        _this.$message.error("复制失败");
        console.log(e);
      });
    },
    //上传封面
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    radiochange(value) {
      let dom = document.getElementsByClassName("quickPop")[0];
      // console.log("dom", dom.firstChild.firstChild);
      console.log("radio", this.radio);
      if (this.radio == 1) {
        dom.firstChild.firstChild.style.cssText =
          "margin-top: 20vh !important;";
      } else {
        // dom.firstChild.firstChild.style.cssText = "margin-top: 1vh !important;";
        dom.firstChild.firstChild.style.cssText =
          "margin-top: 20vh !important;";
        // dom.firstChild.firstChild.style.cssText =
        //   "margin-top: 20vh !important;";
      }
    },
    //
    //搜索课程
    searchClass() {
      // console.log("this.value", this.value);
      let startTime = "";
      let endTime = "";
      if (this.value1 == null) {
        this.value1 = [];
      }
      if (this.value1.length != 0) {
        console.log("this.value1", this.value1);
        // debugger
        startTime = new Date(this.value1[0]).getTime() / 1000;
        endTime = new Date(this.value1[1]).getTime() / 1000;
      }
      this.getRoomPage(this.value, startTime, endTime, this.input1, "");
      this.recoveryScrollTop();
    },
    //刷新课程
    refreshClass() {
      this.value1 = [];
      this.value = "";
      this.input1 = "";
      this.recoveryScrollTop();
      this.getRoomPage("", "", "", "", "");
    },
    //复制课堂号
    copyCode(index) {
      let _this = this;
      let clipboard = new ClipboardJS(".copyTrackingNumber" + index);
      clipboard.on("success", function (e) {
        _this.$message({
          message: "已复制到粘贴板",
          type: "success",
          center: true,
          duration: 1000,
        });
        e.clearSelection();
      });
      clipboard.on("error", function (e) {
        _this.$message.error("复制失败");
        console.log(e);
      });
    },
    //开课成功复制课堂号
    sharePopcopyCode() {
      let _this = this;
      let clipboard = new ClipboardJS(".sharePopNumber");
      clipboard.on("success", function (e) {
        _this.$message({
          message: "已复制到粘贴板",
          type: "success",
          center: true,
          duration: 1000,
        });
        e.clearSelection();
      });
      clipboard.on("error", function (e) {
        _this.$message.error("复制失败");
      });
    },
    //顶部直播状态筛选
    selectStatus() {
      this.searchClass();
    },
    //顶部时间筛选
    selectTime() {
      // console.log('value1',this.value1)
      // this.searchClass();

      // if (this.value1 == null) {
      //   this.value1 = [];
      // }
      this.searchClass();
    },
    handleDate() {
      // let _this = this;
      // setTimeout(() => {
      //   _this.searchClass();
      // }, 1000);
    },
    pickerOptions() {
      // return (
      //   time.getTime() >
      //     new Date(moment().format("YYYY-MM-DD") + " 23:59:59") ||
      //   time.getTime() < new Date(moment().format("YYYY") + "-01-01 00:00:00")
      // );
    },
    //滚动条回到顶部
    recoveryScrollTop() {
      this.$refs.CourseData.scrollTop = 0;
    },
    //获取本人创建课程次数
    async queryDailyCount() {
      let resData = await this.$Api.Live.queryDailyCount();
      this.NumberClasses = resData.data;
      this.quickinput =
        sessionStorage.getItem("teacherName") +
        "的课堂" +
        this.dayjs(new Date()).format("MMDD") +
        "-" +
        this.NumberClasses;
    },
    //关闭弹窗
    closedialog1() {
      this.dialogVisible = false;
      this.Coursetype = null;
    },
    closedialog2() {
      this.dialogVisible4 = false;
    },
    closedialog3() {
      this.dialogVisible3 = false;
    },
    closedialog4() {
      this.dialogVisible2 = false;
      this.greenIndex = null;
      this.greenBtnIndex = null;
    },
    closedialog5() {
      this.dialogVisible5 = false;
      this.Coursetype = null;
      this.radio = "1";
      this.textarea = "";
      this.quickinput = "";
      this.quickvalue = "";
      this.startTime = "";
      this.endTime = "";
      this.editStatus = false;
    },
    OSnow() {
      let agent = navigator.userAgent.toLowerCase();

      let isMac = /macintosh|mac os x/i.test(navigator.userAgent);

      if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
        // alert("这是windows32位系统");
        this.DownloadAddress =
          "https://res.qcloudclass.com/Desktop/1309902731/首师云课堂_1.6.2_win32_Setup.exe";
      }

      if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        // alert("这是windows64位系统");
        this.DownloadAddress =
          "https://res.qcloudclass.com/Desktop/1309902731/首师云课堂_1.6.2_Setup.exe";
      }

      if (isMac) {
        this.DownloadAddress =
          "https://res.qcloudclass.com/Desktop/1309902731/首师云课堂-1.6.2.dmg";
        // alert("这是mac系统");
      }
    },
    //判断电脑上是否安装客户端
    openUriWithInputTimeoutHack(uri, failCb, successCb) {
      let target = document.createElement("input");
      target.style.width = "0";
      target.style.height = "0";
      target.style.position = "fixed";
      target.style.top = "0";
      target.style.left = "0";
      document.body.appendChild(target);
      target.focus();
      let _this = this;
      var handler = this._registerEvent(target, "blur", onBlur);

      function onBlur() {
        if (_this.throttleStatus) {
          console.log("blur");
          successCb && successCb();
          handler.remove();
          clearTimeout(timeout);
          document.body.removeChild(target);
        }
      }
      location.href = uri;
      var timeout = setTimeout(function () {
        console.log("setTimeout");
        failCb && failCb();
        handler.remove();
        document.body.removeChild(target);
      }, 1000);

      //  this.throttleStatus = false;
    },

    _registerEvent(target, eventType, cb) {
      console.log("target.addEventListener", target.addEventListener);
      if (target.addEventListener) {
        target.addEventListener(eventType, cb);
        return {
          remove: function () {
            target.removeEventListener(eventType, cb);
          },
        };
      } else {
        target.attachEvent(eventType, cb);
        return {
          remove: function () {
            target.detachEvent(eventType, cb);
          },
        };
      }
    },
    //获取用户信息
    async Personnel_information() {
      let resData = await this.$Api.Home.getUserInfo();
      console.log("resData", resData);
      this.teacherId = resData.data.id;
    },
    startChange() {
      let date1 = this.dayjs(new Date()).format("YYYY-MM-DD"); //当前日期
      if (this.startTime.slice(0, 2) == "23") {
        this.endTime = "23:55";
      } else {
        this.endTime = this.dayjs(date1 + " " + this.startTime)
          .add(1, "hour")
          .format("HH:mm");
      }

      // this.minTime2 = this.dayjs(date1 + " " + this.startTime)
      //   .format("HH:mm");
      // this.correct(date1);
      // console.log("endTime", this.endTime);
      // this.Deadline2 = this.dayjs(date1 + " " + this.startTime)
      //   .add(5, "hour")
      //   .format("HH:mm");
      // this.minTime2 = this.dayjs(date1 + " " + this.startTime)
      //   .add(5, "minute")
      //   .format("HH:mm");
      // this.correct(date1);
    },
    pickertTime() {
      let date1 = this.dayjs(this.quickvalue).format("YYYY-MM-DD");
      let date2 = this.dayjs(new Date()).format("YYYY-MM-DD");
      if (date1 == date2) {
        let currentTime = this.dayjs(new Date()).format("HH:mm");
        console.log("currentTime-----", currentTime.slice(-2));
        if (
          currentTime.slice(0, 2) == "23" &&
          Number(currentTime.slice(-2)) >= 55
        ) {
          this.quickvalue = this.dayjs(new Date())
            .add(1, "day")
            .format("YYYY-MM-DD");
          this.startTime = "08:00";
          this.minTime1 = "00:00";
          this.endTime = "09:00";
          this.minTime2 = "00:00";
        } else {
          this.quickvalue = this.dayjs(new Date()).format("YYYY-MM-DD");
          // // 矫正时间
          let startTimeSt =
            5 - (currentTime.substr(currentTime.length - 1, 1) % 5);
          this.startTime = this.dayjs(new Date())
            .add(startTimeSt + 10, "minute")
            .format("HH:mm");
          if (currentTime.slice(0, 2) == "23") {
            this.endTime = "23:55";
          } else {
            this.endTime = this.dayjs(new Date())
              .add(startTimeSt + 10, "minute")
              .add(1, "hour")
              .format("HH:mm");
          }
          this.minTime1 = this.dayjs(new Date()).format("HH:mm");
          this.minTime2 = this.dayjs(new Date()).format("HH:mm");
        }
      } else {
        this.startTime = "08:00";
        this.minTime1 = "00:00";
        this.endTime = "09:00";
        this.minTime2 = "00:00";
      }
      // console.log("quickvalue", typeof(date1),date2);
    },
    correct(date1) {
      let num = 24 - Number(this.startTime.slice(0, 2));
      if (num > 5) {
        this.endTime = this.dayjs(date1 + " " + this.startTime)
          .add(1, "hour")
          .format("HH:mm");
        console.log("endTime", this.endTime);
        this.Deadline2 = this.dayjs(date1 + " " + this.startTime)
          .add(5, "hour")
          .format("HH:mm");
        this.minTime2 = this.dayjs(date1 + " " + this.startTime)
          .add(5, "minute")
          .format("HH:mm");
      } else if (num == 0) {
        // this.endTime = this.dayjs(date1 + " " + this.startTime)
        //   .add(1, "hour")
        //   .format("HH:mm");
        // console.log("endTime", this.endTime);
        // this.Deadline2 = this.dayjs(date1 + " " + this.startTime)
        //   .add(5, "hour")
        //   .format("HH:mm");
        // this.minTime2 = this.dayjs(date1 + " " + this.startTime)
        //   .add(5, "minute")
        //   .format("HH:mm");
      } else {
        this.endTime = this.dayjs(date1 + " " + this.startTime)
          .add(1, "hour")
          .format("HH:mm");
        console.log("endTime", this.endTime);
        this.Deadline2 = this.dayjs(date1 + " " + this.startTime)
          .add(num, "hour")
          .format("HH:mm");
        this.minTime2 = this.dayjs(date1 + " " + this.startTime)
          .add(num, "minute")
          .format("HH:mm");
      }

      console.log("纠正num", num);
      console.log("纠正", this.startTime.slice(0, 2));
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    // let that = this;
    //  that.$AccessPortrait.Portrait().then((res) => {
    //   console.log("that", res);
    //   // that.teacherName = res.data.name;
    //   sessionStorage.setItem("teacherName", res.data.name);
    //   sessionStorage.setItem("teacherId", res.data.id);
    //   //  that.teacherId = res.data.id;
    // });
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.Personnel_information();
    this.getRoomPage("", "", "", "", "");
    this.dictionary();
    // this.teacherId = sessionStorage.getItem("teacherId");
    document.addEventListener("click", (e) => {
      if (this.$refs.showPanel[this.greenIndex] != undefined) {
        let isSelf = this.$refs.showPanel[this.greenIndex].contains(e.target);
        if (!isSelf) {
          if (this.greenBtnIndex == 3) {
            this.greenBtnIndex = null;
            this.moreIndex = null;
          }
        }
      }
    });
    this.electronJoinClass = window.electronJoinClass;
    // console.log("window.electronJoinClass", window.electronJoinClass);
    this.OSnow();

    // console.log('window.location.href',window.location.href)
    //  alert(window.location.href)
  },
};
</script>
<style lang='less' scoped>
@import "./less/livePanel";

.el-select-dropdown__item.selected {
  color: #21bd99 !important;
}

/deep/.el-picker-panel {
  border: 1px solid #000 !important;

  .el-date-table {
    td {
      .today {
        span {
          color: #21bd99 !important;
        }
      }
    }
  }
}

/deep/.el-date-table td.available:hover {
  color: #21bd99 !important;
}

/deep/.el-dropdown-menu__item {
  padding: 0 !important;
  width: 110px;
  text-align: center;
  //  width: 300px !important;
}

/deep/.el-date-table td.today span {
  color: #21bd99 !important;
}

/deep/.time-select-item.selected:not(.disabled) {
  color: #409eff !important;
}
</style>
<style lang='less'>
.el-tooltip__popper.is-light {
  margin-top: 20px;
  border: 2px solid #21bd99;
  color: #999999;
  z-index: 1;
}

.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #21bd99 !important;

  margin-top: -1px;
}

.el-tooltip__popper[x-placement^="bottom"] .popper__arrow:after {
  border-bottom-color: white !important;
}

.el-message {
  z-index: 9999 !important;
}
</style>