<!--  -->
<template>
  <div class="entrance">
    <div class="Header flex-align-between">
      <div class="entrances">
        <div class="head flex-align">
          <img :src="avatar1" v-if="succeed" />
          <img :src="require('../../assets/homepage/tou.png')" v-else />
        </div>
        <div class="type">
          <ul class="flex-between">
            <!-- v-for="(item, index) in tackclass"
              :key="index" -->
            <li class="flex-column-center">
              <div class="flex-align-between">
                <span class="teacherName">{{ teacherName }}</span>
                <span class="toGreet">你好</span>
              </div>
              <!-- <img
                :src="index == iconIndex ? item.imgs2 : item.imgs1"
                @click="site(item.name, index)"
              />
              <span
                @click="site(item.name, index)"
                :style="{ color: index == iconIndex ? '#FBAE57' : '' }"
                >{{ item.name }}</span
              > -->
            </li>
          </ul>
        </div>
      </div>
      <div class="back flex-align-between">
        <img :src="require('@/assets/homepage/exit.png')" @click="exit" />
        <!-- <span @click="goback">返回</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      tackclass: [
        // {
        //   name: "首页",
        //   href: "/home",
        //   imgs1: require("../../assets/live/1.png"),
        //   imgs2: require("../../assets/live/1-2.png"),
        // },
        // {
        //   name: "上课",
        //   href: "/attend",
        //   imgs1: require("../../assets/live/2.png"),
        //   imgs2: require("../../assets/live/2-2.png"),
        // },
        // {
        //   name: "活动",
        //   href: "/attend",
        //   imgs1: require("../../assets/live/3.png"),
        //   imgs2: require("../../assets/live/3-2.png"),
        // },
        {
          name: "直播",
          href: "/showreel",
          imgs1: require("../../assets/live/4.png"),
          imgs2: require("../../assets/live/4-2.png"),
        },
        // {
        //   name: "作品集",
        //   href: "/showreel",
        //   imgs1: require("../../assets/live/5.png"),
        //   imgs2: require("../../assets/live/5-2.png"),
        // },
      ],
      avatar1: "",
      succeed: false,
      iconIndex: 0,
      teacherName: "",
    };
  },
  props: ["sitedata"],
  //方法
  methods: {
    ...mapMutations(["setIndex"]),
    goback() {
      if (this.sitedata) {
        this.$router.go(-1);
      } else {
        this.$emit("renewal", true);
      }
    },
    site(value, index) {
      if (value == "首页") {
        this.$router.push({
          path: "/home",
        });
        this.$emit("renewal", true);
      } else if (value == "上课") {
        this.$router.push({
          path: "/home",
        });
        this.$emit("renewal", true);
      } else if (value == "活动") {
        this.access();
        // this.$router.push({
        //       path: '/activity',
        // });
      } else if (value == "直播") {
        this.$router.push({
          // path: '/sampleReels',
          path: "/livePanel",
        });
        this.$emit("renewal", true);
      } else {
        this.$router.push({
          // path: '/sampleReels',
          path: "/showreel",
        });
        this.$emit("renewal", true);
      }
    },
    errorimg(src) {
      this.avatar1 = "https://apitest.lezhireading.com/uploads/" + src;
      let that = this;
      let img = new Image();
      img.src = this.avatar1;
      img.onload = function () {
        that.succeed = true;
      };
      img.onerror = function () {
        that.succeed = false;
      };
    },
    async access() {
      let _this = this;
      let resData = await _this.$Api.Home.getcheckTencentUser();
      if (resData.data) {
        let data = await _this.$Api.Home.checkSchoolRedirectUrl();
        if (data.data) {
          window.open(data.data);
        } else {
          let resData = await _this.$Api.Myactivity.getActivityList();
          if (resData.data.length == 0) {
            _this.hint_data("暂无活动");
            _this.centerDialogVisible2 = true;
          } else {
            _this.$router.push({
              name: "activity",
            });
          }
        }
      } else {
        let resData = await _this.$Api.Myactivity.getActivityList();
        if (resData.data.length == 0) {
          _this.hint_data("暂无活动");
          _this.centerDialogVisible2 = true;
        } else {
          _this.$router.push({
            name: "activity",
          });
        }
      }

      // let resData = await this.$Api.Myactivity.getActivityList();
      // if (resData.data.length == 0) {
      //   this.$emit("changTip", true);
      // } else {
      //   this.$router.push({
      //     name: "activity",
      //   });
      // }
    },
    //退出
    exit() {
      // this.message_data(true)
      this.$confirm("此操作将退出应用, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        center: true,
        customClass: "MessClass",
        confirmButtonClass: "warningClass",
      })
        .then(() => {
          this.$router.push({
            name: "logins",
            params: {
              type: 1,
            },
          });
        })
        .catch(() => {});
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    let that = this;
    // this.$AccessPortrait.Portrait().then((res) => {
    //   that.errorimg(res);
    // });
    that.$AccessPortrait.Portrait().then((res) => {
      console.log("that", res);
      that.teacherName = res.data.name;
      sessionStorage.setItem("teacherName", res.data.name);
      sessionStorage.setItem("teacherId", res.data.id);
      that.errorimg(res.data.avatar);
    });
  },
  computed: {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    let _this = this;
    this.iconIndex = sessionStorage.getItem("iconIndex");
  },
};
</script>
<style lang='less' scoped>
@import "./less/entranceClient";
</style>